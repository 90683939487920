import { Card, Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { WorkList } from "../Data/WorkList";
import { EducationList } from "../Data/EducationList";

export const Experience = () => {
  const { t } = useTranslation();

  return (
    <Container className="experience-main-container">
      <Row className="experience-title">
        <h1>{t("experience.work")}</h1>
      </Row>
      <WorkList listAll={true} />

      <Row className="experience-title">
        <h1>{t("experience.education")}</h1>
      </Row>
      <EducationList listAll={true} />

      <Outlet />
    </Container>
  );
};
