import React from "react";
import { useTranslation } from "react-i18next";

import { Card, Col, Row } from "react-bootstrap";

import unisys from "../Assets/Images/unisys.jpg";
import dekra from "../Assets/Images/dekra.png";
import oehtb from "../Assets/Images/oehtb.jpg";
import evn from "../Assets/Images/evn.png";
import schrack from "../Assets/Images/schrack.png";

export interface Work {
  name: string;
  company: string;
  link: string;
  logo: string;
  place: string;
  from: string;
  to: string;
  descriptions?: string[];
}
interface WorksProps {
  listAll: boolean;
}

export const WorkList: React.FC<WorksProps> = ({ listAll }) => {
  const { t } = useTranslation();

  let works: Work[] = [
    {
      name: t("work-list.unisys.name"),
      company: "Unisys",
      link: "https://www.unisys.com/de/",
      logo: unisys,
      place: t("work-list.unisys.place"),
      from: t("work-list.unisys.from"),
      to: t("work-list.unisys.to"),
      descriptions: [t("work-list.unisys.descriptions.0")],
    },
    {
      name: t("work-list.dcg.name"),
      company: "DEKRA Certification GmbH",
      link: "https://www.dekra-certification.de/de/startseite/",
      logo: dekra,
      place: t("work-list.dcg.place"),
      from: t("work-list.dcg.from"),
      to: t("work-list.dcg.to"),
      descriptions: [
        t("work-list.dcg.descriptions.0"),
        t("work-list.dcg.descriptions.1"),
        t("work-list.dcg.descriptions.2"),
      ],
    },
    {
      name: t("work-list.oehtb.name"),
      company: "ÖHTB Wohnen GmbH",
      link: "https://oehtb.at/wohnen",
      logo: oehtb,
      place: t("work-list.oehtb.place"),
      from: t("work-list.oehtb.from"),
      to: t("work-list.oehtb.to"),
      descriptions: [
        t("work-list.oehtb.descriptions.0"),
        t("work-list.oehtb.descriptions.1"),
      ],
    },

    {
      name: t("work-list.evn.name"),
      company: "EVN AG",
      link: "https://www.evn.at/",
      logo: evn,
      place: t("work-list.evn.place"),
      from: t("work-list.evn.from"),
      to: t("work-list.evn.to"),
      descriptions: [
        t("work-list.evn.descriptions.0"),
        t("work-list.evn.descriptions.1"),
      ],
    },
    {
      name: t("work-list.schrack.name"),
      company: "Schrack Seconet AG",
      link: "https://www.schrack-seconet.com/de/",
      logo: schrack,
      place: t("work-list.schrack.place"),
      from: t("work-list.schrack.from"),
      to: t("work-list.schrack.to"),
      descriptions: [
        t("work-list.schrack.descriptions.0"),
        t("work-list.schrack.descriptions.1"),
      ],
    },
  ];

  return listAll ? (
    <>
      {works.map((work, index) => (
        <Card key={index} className="experience-item">
          <Col className="d-flex">
            <img src={work.logo} alt="Logo" className="experience-image" />
            <Row className="experience-item-title">
              <h4>
                {work.name} @ <a href={work.link}>{work.company}</a> (
                {work.place})
              </h4>
              <p>
                {work.from} - {work.to}
              </p>
            </Row>
          </Col>
          <ul>
            {work.descriptions &&
              work.descriptions.map((description, index2) => (
                <li key={index2}>{description}</li>
              ))}
          </ul>
        </Card>
      ))}
    </>
  ) : (
    <Card className="experience-item">
      <Col className="d-flex">
        <img src={works[0].logo} alt="Logo" className="experience-image" />
        <Row className="experience-item-title">
          <h4>
            {works[0].name} @ <a href={works[0].link}>{works[0].company}</a> (
            {works[0].place})
          </h4>
          <p>
            {works[0].from} - {works[0].to}
          </p>
        </Row>
      </Col>
    </Card>
  );
};
