import { Container, Row, Col } from "react-bootstrap";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { socials } from "../Data/SocialList";

const Footer = () => {
  return (
    <Container className="d-flex flex-column footer">
      <Col className="d-flex justify-content-center align-items-center footer-socials">
        <IconContext.Provider value={{ size: "20" }}>
          {socials.map((social, index) => (
            <Col key={index} md="auto">
              <Link
                className={social?.className + " footer-social-icon"}
                to={social.url}
                target="_blank"
              >
                {social.icon}
              </Link>
            </Col>
          ))}
        </IconContext.Provider>
      </Col>
      <Row className="d-flex justify-content-center footer-copyright">
        Jay Quidet © {new Date().getFullYear()} • Personal Portfolio
      </Row>
    </Container>
  );
};

export default Footer;
