import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {
  MdOutlineHome,
  MdPersonOutline,
  MdOutlineWorkOutline,
  MdCode,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "./LanguageSwitcher";

const Navigation = () => {
  const { t } = useTranslation();

  return (
    <Navbar variant="dark" className="px-3" expand="sm" collapseOnSelect>
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <div className="d-flex align-items-center">
            <h1 style={{ fontFamily: "SquareFont", fontSize: "3em" }}>J</h1>
            <h1
              style={{
                fontFamily: "SquareFont",
                color: "#00ffff",
                fontSize: "3em",
              }}
            >
              Q
            </h1>
          </div>
        </Navbar.Brand>
        <LanguageSwitcher />
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end" id="navbarCollapse">
          <Nav className="align-items-center" style={{ columnGap: "1em" }}>
            <Nav.Link as={NavLink} to="/" eventKey="1">
              <MdOutlineHome />
              <p>{t("navbar.home")}</p>
            </Nav.Link>
            <Nav.Link as={NavLink} to="/about" eventKey="2">
              <MdPersonOutline />
              <p>{t("navbar.about")}</p>
            </Nav.Link>
            <Nav.Link as={NavLink} to="/experience" eventKey="3">
              <MdOutlineWorkOutline />
              <p>{t("navbar.experience")}</p>
            </Nav.Link>
            <Nav.Link as={NavLink} to="/projects" eventKey="4">
              <MdCode />
              <p>{t("navbar.projects")}</p>
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/cv" eventKey="4">
							<span>
								<ImProfile />{' '}CV
							</span>
						</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
