import { Suspense, useEffect } from "react";
import { Loader } from "./Components/Loader";
import { Navigate, Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home";
import { About } from "./Pages/About";
import { Experience } from "./Pages/Experience";
import { Projects } from "./Pages/Projects";
import Footer from "./Components/Footer";
import Navigation from "./Components/Navigation";
import CursorTrail from "./Components/CursorTrail";
import "./Assets/Styles/index.scss";

function App() {
  useEffect(() => {
    let encEmail = "amF5LnF1aWRldEBob3RtYWlsLmNvbQ==";
    const forms = document.getElementsByClassName("contact");
    for (var index = 0; index < forms.length; ++index) {
      forms[index].setAttribute(
        "href",
        "mailto:".concat(window.atob(encEmail))
      );
    }

    console.log(forms);
  }, []);

  return (
    <div className="App">
      <CursorTrail />
      <Navigation />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
