import {
  SiCsharp,
  SiJavascript,
  SiTypescript,
  SiKotlin,
  SiSwift,
  SiDart,
  SiCplusplus,
  SiPerl,
  SiDotnet,
  SiFlutter,
  SiExpress,
  SiIonic,
  SiJetbrains,
  SiVisualstudio,
  SiVisualstudiocode,
  SiPostgresql,
  SiDocker,
  SiAzuredevops,
  SiJenkins,
} from "react-icons/si";
import { DiReact, DiNodejs } from "react-icons/di";
import { TbSql, TbBrandReactNative } from "react-icons/tb";
import { FaAws } from "react-icons/fa6";
import { FaAngular, FaJira, FaPhp, FaTrello } from "react-icons/fa";

export interface Skill {
  name: string;
  icon: JSX.Element;
}

export const progLanguages: Skill[] = [
  {
    name: "C#",
    icon: <SiCsharp />,
  },
  {
    name: "Javascript",
    icon: <SiJavascript />,
  },
  {
    name: "TypeScript",
    icon: <SiTypescript />,
  },
  {
    name: "SQL",
    icon: <TbSql />,
  },
  {
    name: "Kotlin",
    icon: <SiKotlin />,
  },
  {
    name: "Swift",
    icon: <SiSwift />,
  },
  {
    name: "Dart",
    icon: <SiDart />,
  },
  {
    name: "PHP",
    icon: <FaPhp />,
  },
  {
    name: "C++",
    icon: <SiCplusplus />,
  },
  {
    name: "Perl",
    icon: <SiPerl />,
  },
];

export const frameworks: Skill[] = [
  {
    name: ".NET",
    icon: <SiDotnet />,
  },
  {
    name: "React",
    icon: <DiReact />,
  },
  {
    name: "Angular",
    icon: <FaAngular />,
  },
  {
    name: "Node.js",
    icon: <DiNodejs />,
  },
  {
    name: "Express.js",
    icon: <SiExpress />,
  },
  {
    name: "Ionic",
    icon: <SiIonic />,
  },
  {
    name: "React Native",
    icon: <TbBrandReactNative />,
  },
  {
    name: "Flutter",
    icon: <SiFlutter />,
  },
];

export const toolsAndServices: Skill[] = [
  {
    name: "JetBrains",
    icon: <SiJetbrains />,
  },
  {
    name: "Visual Studio",
    icon: <SiVisualstudio />,
  },
  {
    name: "VS Code",
    icon: <SiVisualstudiocode />,
  },
  {
    name: "PostgreSQL",
    icon: <SiPostgresql />,
  },
  {
    name: "Docker",
    icon: <SiDocker />,
  },
  {
    name: "Azure Devops",
    icon: <SiAzuredevops />,
  },
  {
    name: "AWS",
    icon: <FaAws />,
  },
  {
    name: "Jira",
    icon: <FaJira />,
  },
  {
    name: "Trello",
    icon: <FaTrello />,
  },
  {
    name: "Jenkins",
    icon: <SiJenkins />,
  },
];
