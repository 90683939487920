import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { FaEnvelope } from "react-icons/fa";

export interface Social {
  className?: string;
  name: string;
  icon: JSX.Element;
  url: string;
}

export const socials: Social[] = [
  {
    className: "contact",
    name: "E-Mail",
    icon: <FaEnvelope className="me-2" />,
    url: "mailto:",
  },
  {
    name: "GitHub",
    icon: <AiFillGithub className="me-2" />,
    url: "https://github.com/jayq97",
  },
  {
    name: "LinkedIn",
    icon: <AiFillLinkedin className="me-2" />,
    url: "https://www.linkedin.com/in/jayq97/",
  },
];
