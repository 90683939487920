import React from "react";
import { useTranslation } from "react-i18next";

import { Card, Col, Row } from "react-bootstrap";

import technikum from "../Assets/Images/technikum.png";
import htlwien10 from "../Assets/Images/htlwien10.png";

export interface Education {
  name: string;
  institute: string;
  link: string;
  logo: string;
  place: string;
  from: string;
  to: string;
  descriptions: string[];
}

interface EducationsProps {
  listAll: boolean;
}

export const EducationList: React.FC<EducationsProps> = ({ listAll }) => {
  const { t } = useTranslation();

  let educations: Education[] = [
    {
      name: t("education-list.technikum.name"),
      institute: "Technikum Wien",
      link: "https://www.technikum-wien.at/",
      logo: technikum,
      place: t("education-list.technikum.place"),
      from: t("education-list.technikum.from"),
      to: t("education-list.technikum.to"),
      descriptions: [
        t("education-list.technikum.descriptions.0"),
        t("education-list.technikum.descriptions.1"),
      ],
    },
    {
      name: t("education-list.htlwien10.name"),
      institute: "HTL Wien 10",
      link: "https://www.htlwien10.at/edu/",
      logo: htlwien10,
      place: t("education-list.htlwien10.place"),
      from: t("education-list.htlwien10.from"),
      to: t("education-list.htlwien10.to"),
      descriptions: [
        t("education-list.htlwien10.descriptions.0"),
        t("education-list.htlwien10.descriptions.1"),
      ],
    },
  ];

  return listAll ? (
    <>
      {educations.map((education, index) => (
        <Card key={index} className="experience-item">
          <Col className="d-flex">
            <img src={education.logo} alt="Logo" className="experience-image" />
            <Row className="experience-item-title">
              <h4>
                {education.name} @{" "}
                <a href={education.link}>{education.institute}</a> (
                {education.place})
              </h4>
              <p>
                {education.from} - {education.to}
              </p>
            </Row>
          </Col>
          <ul>
            {education.descriptions.map((description, index2) => (
              <li key={index2}>{description}</li>
            ))}
          </ul>
        </Card>
      ))}
    </>
  ) : (
    <Card className="experience-item">
      <Col className="d-flex">
        <img src={educations[0].logo} alt="Logo" className="experience-image" />
        <Row className="experience-item-title">
          <h4>
            {educations[0].name} @{" "}
            <a href={educations[0].link}>{educations[0].institute}</a> (
            {educations[0].place})
          </h4>
          <p>
            {educations[0].from} - {educations[0].to}
          </p>
        </Row>
      </Col>
    </Card>
  );
};
