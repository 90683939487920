import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importiere Sprachdateien
import translationEN from './Languages/english.json';
import translationDE from './Languages/german.json';

const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en', // Standard-Sprache
        fallbackLng: 'en', // Fallback auf Englisch, wenn eine Übersetzung nicht verfügbar ist
        interpolation: {
            escapeValue: false // React wird die Escape-Funktion benutzen
        }
    });

export default i18n;