import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Link } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";

import profilePic from "../Assets/Images/profile-picture.png";
import {
  progLanguages,
  frameworks,
  toolsAndServices,
} from "../Data/SkillsetList";

import { socials } from "../Data/SocialList";

export const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    let encEmail = "amF5LnF1aWRldEBob3RtYWlsLmNvbQ==";
    const forms = document.getElementsByClassName("contact");
    for (var index = 0; index < forms.length; ++index) {
      forms[index].setAttribute(
        "href",
        "mailto:".concat(window.atob(encEmail))
      );
    }

    console.log(forms);
  }, []);

  return (
    <Container className="about-main-container">
      <Row className="about-title">
        <h1>{t("navbar.about")}</h1>
      </Row>
      <Row className="about-body">
        <Col md={5} className="about-data align-items-center text-center">
          <img src={profilePic} alt="Profile" className="about-image"></img>
          <h1>Jay Quidet</h1>

          <p>
            <FaMapMarkerAlt className="me-2" /> {t("about.location")}
          </p>
          {socials.map((social, index) => (
            <p key={index}>
              {social.icon}
              <Link
                className={social?.className + " footer-social-icon me-2"}
                to={social.url}
                target="_blank"
              >
                {social.name}
              </Link>
            </p>
          ))}
        </Col>
        <Col md={7} className="about-text">
          <h3>{t("about.greeting")}</h3>
          <p>{t("about.introduction.0")}</p>
          <p>
            {t("about.introduction.1")}
            <strong>React</strong>
            {t("about.introduction.2")}
            <strong>.NET</strong>
            {t("about.introduction.3")}
          </p>

          <p>
            {" "}
            {t("about.introduction.4")}{" "}
            <strong>{t("about.introduction.5")}</strong>{" "}
            {t("about.introduction.6")}
            <strong>Application Development Engineer </strong>
            {t("about.introduction.7")}
            <strong> Unisys </strong> {t("about.introduction.8")}
          </p>
        </Col>
      </Row>

      <Row className="about-skills">
        <Col>
          <h2>{t("about.programming-languages")}</h2>
          <Row className="skill-row">
            {progLanguages.map((skill, index) => (
              <Col xs={6} sm={3} md={2} key={index}>
                <div className="skill-box d-flex">
                  {skill.icon}
                  <span className="lineUp">{skill.name}</span>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row className="about-skills">
        <Col>
          <h2>Libraries & Frameworks</h2>
          <Row className="skill-row">
            {frameworks.map((skill, index) => (
              <Col xs={6} sm={3} md={2} key={index}>
                <div className="skill-box d-flex">
                  {skill.icon}
                  <span className="lineUp">{skill.name}</span>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row className="about-skills">
        <Col>
          <h2>Tools & Services</h2>
          <Row className="skill-row">
            {toolsAndServices.map((skill, index) => (
              <Col xs={6} sm={3} md={2} key={index}>
                <div className="skill-box d-flex">
                  {skill.icon}
                  <span className="lineUp">{skill.name}</span>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Outlet />
    </Container>
  );
};
