import React, { useEffect, useRef } from "react";

class Point {
  x: number;
  y: number;
  lifetime: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.lifetime = 0;
  }
}

interface CanvasProps {
  // Add any props if needed
}

const CursorTrail: React.FC<CanvasProps> = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [state, setState] = React.useState({
    cHeight: window.innerHeight,
    cWidth: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setState({
        cHeight: window.innerHeight,
        cWidth: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    // If the device supports cursors, start animation.
    if (matchMedia("(pointer:fine)").matches) {
      startAnimation();
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const startAnimation = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");

    const points: Point[] = [];

    const addPoint = (x: number, y: number) => {
      const point = new Point(x, y);
      points.push(point);
    };

    document.addEventListener(
      "mousemove",
      ({ pageX, pageY }) => {
        addPoint(pageX - window.pageXOffset, pageY - window.pageYOffset);
      },
      false
    );

    const animatePoints = () => {
      if (!ctx) return;
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      const duration = (0.85 * (1 * 1000)) / 60; // Last 80% of a frame per point

      for (let i = 0; i < points.length; ++i) {
        const point = points[i];
        let lastPoint;

        if (points[i - 1] !== undefined) {
          lastPoint = points[i - 1];
        } else lastPoint = point;

        point.lifetime += 1;

        if (point.lifetime > duration) {
          // If the point dies, remove it.
          points.shift();
        } else {
          // Otherwise animate it:

          // As the lifetime goes on, lifePercent goes from 0 to 1.
          const lifePercent = point.lifetime / duration;
          const spreadRate = 12 * (1 - lifePercent);

          ctx.lineJoin = "round";
          ctx.lineWidth = spreadRate;

          const red = Math.floor(255 - 55 * lifePercent);
          const green = Math.floor(255 * lifePercent);
          const blue = 0;
          ctx.strokeStyle = `rgb(${red},${green},${blue})`;

          ctx.beginPath();

          ctx.moveTo(lastPoint.x, lastPoint.y);
          ctx.lineTo(point.x, point.y);

          ctx.stroke();
          ctx.closePath();
        }
      }
      requestAnimationFrame(animatePoints);
    };

    animatePoints();
  };

  return (
    <canvas
      className="cursor-trail"
      ref={canvasRef}
      width={state.cWidth}
      height={state.cHeight}
    />
  );
};

export default CursorTrail;
