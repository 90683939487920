import { useTranslation } from "react-i18next";
import { Card, Col, Container, Row, Badge } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { ProjectList } from "../Data/ProjectList";

export const Projects = () => {
  const { t } = useTranslation();

  return (
    <Container className="projects-main-container">
      <Row className="projects-title">
        <h1>{t("navbar.projects")}</h1>
      </Row>
      <Row>
        <ProjectList listAll={true} />
      </Row>
      <Outlet />
    </Container>
  );
};
