import React from "react";
import { useTranslation } from "react-i18next";

import { Card, Col, Row, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

export interface Project {
  name: string;
  url: string;
  from: string;
  to: string;
  descriptions: string[];
  technologies: string[];
  roles: string[];
}

interface ProjectsProps {
  listAll: boolean;
}

export const ProjectList: React.FC<ProjectsProps> = ({ listAll }) => {
  const { t } = useTranslation();

  let projects: Project[] = [
    /*{
    name: "ChronoPlan (in development)",

    url: "https://github.com/jayq97/",
    from: "2023 Dec",
    to: "now",
    descriptions: [
      "ChronoPlan is an app that allows the user to enter appointments in the calendar and also set up to-dos and habits.",
    ],
    technologies: ["TypeScript", "React", "Java", "Spring"],
    roles: ["Frontend Design", "Backend Architecture"],
  },*/
    {
      name: "Parcel Track'n'Trace",

      url: "https://github.com/jayq97/Parcel-Track-n-Trace",
      from: t("project-list.parceltnt.from"),
      to: t("project-list.parceltnt.to"),
      descriptions: [
        t("project-list.parceltnt.descriptions.0"),
        t("project-list.parceltnt.descriptions.1"),
        t("project-list.parceltnt.descriptions.2"),
      ],
      technologies: [
        "C#",
        "ASP.NET",
        "Entity Framework",
        "AutoMapper",
        "MS SQL",
        "NUnit",
        "Moq",
      ],
      roles: [
        t("project-list.parceltnt.roles.0"),
        t("project-list.parceltnt.roles.1"),
        t("project-list.parceltnt.roles.2"),
      ],
    },
    {
      name: "Urban Heat Island",

      url: "https://github.com/jayq97/UrbanHeatIslands-Frontend",
      from: t("project-list.uhi.from"),
      to: t("project-list.uhi.to"),
      descriptions: [
        t("project-list.uhi.descriptions.0"),
        t("project-list.uhi.descriptions.1"),
        t("project-list.uhi.descriptions.2"),
      ],
      technologies: [
        "JavaScript",
        "React",
        "Leaflet.js",
        "D3.js",
        "OpenWeatherMap API",
        "Node.js",
        "Express.js",
        "PostgreSQL",
      ],
      roles: [t("project-list.uhi.roles.0"), t("project-list.uhi.roles.1")],
    },
    {
      name: "TourPlanner",

      url: "https://github.com/jayq97/TourPlanner",
      from: t("project-list.tourplanner.from"),
      to: t("project-list.tourplanner.to"),
      descriptions: [
        t("project-list.tourplanner.descriptions.0"),
        t("project-list.tourplanner.descriptions.1"),
        t("project-list.tourplanner.descriptions.2"),
      ],
      technologies: ["C#", "WPF", "PostgreSQL", "MapQuest API", "NUnit", "Moq"],
      roles: [
        t("project-list.tourplanner.roles.0"),
        t("project-list.tourplanner.roles.1"),
        t("project-list.tourplanner.roles.2"),
      ],
    },
    {
      name: "Monster Trading Card Game",

      url: "https://github.com/jayq97/MonsterTradingCardGame",
      from: t("project-list.mtcg.from"),
      to: t("project-list.mtcg.to"),
      descriptions: [
        t("project-list.mtcg.descriptions.0"),
        t("project-list.mtcg.descriptions.1"),
        t("project-list.mtcg.descriptions.2"),
      ],
      technologies: ["C#", "PostgreSQL", "NUnit", "Moq"],
      roles: [t("project-list.mtcg.roles.0"), t("project-list.mtcg.roles.1")],
    },
    {
      name: "Gamehub",

      url: "https://github.com/jayq97/Gamehub",
      from: t("project-list.gamehub.from"),
      to: t("project-list.gamehub.to"),
      descriptions: [
        t("project-list.gamehub.descriptions.0"),
        t("project-list.gamehub.descriptions.1"),
        t("project-list.gamehub.descriptions.2"),
      ],
      technologies: ["JavaScript", "Socket.IO", "PHP", "MySQL"],
      roles: [
        t("project-list.gamehub.roles.0"),
        t("project-list.gamehub.roles.1"),
      ],
    },
  ];

  return listAll ? (
    <>
      {projects.map((project, index) => (
        <Col className="d-flex" key={index} lg={6} md={12}>
          <Card as={Link} to={project.url} className="project-box">
            <h2 className="project-title">{project.name}</h2>
            <p>
              {project.from} - {project.to}
            </p>

            <ul>
              {project.descriptions.map((description, index2) => (
                <li key={index2}>{description}</li>
              ))}
            </ul>
            <p>{t("project-list.my-roles")}:</p>
            <div className="project-tag-container">
              {project.roles.map((role, index) => (
                <Badge
                  pill
                  key={index}
                  bg="light"
                  text="dark"
                  className="mr-2 mb-2"
                >
                  {role}
                </Badge>
              ))}
            </div>
            <br />
            <p>{t("project-list.used-technologies")}:</p>
            <div className="project-tag-container">
              {project.technologies.map((technology, index) => (
                <Badge
                  pill
                  key={index}
                  bg="warning"
                  text="dark"
                  className="mr-2 mb-2"
                >
                  {technology}
                </Badge>
              ))}
            </div>
          </Card>
        </Col>
      ))}
    </>
  ) : (
    <>
      {projects.slice(0, 2).map((project, index) => (
        <Col className="d-flex" key={index} lg={6} md={12}>
          <Card as={Link} to={project.url} className="project-box">
            <h2 className="project-title">{project.name}</h2>
            <p>
              {project.from} - {project.to}
            </p>
            <p>{project.descriptions[0]}</p>

            <div className="project-tag-container">
              {project.technologies.map((technology, index) => (
                <Badge
                  key={index}
                  bg="warning"
                  style={{ color: "black" }}
                  className="mr-2 mb-2"
                >
                  {technology}
                </Badge>
              ))}
            </div>
          </Card>
        </Col>
      ))}
    </>
  );
};
