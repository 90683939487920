import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import i18n from "../i18n";
import { useState } from "react";

const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
};

export function LanguageSwitcher() {
  const [activeButton, setActiveButton] = useState(1);

  const handleChangeLanguage = (language: string) => {
    changeLanguage(language);
  };

  const handleButtonClick = (buttonId: any, language: string) => {
    setActiveButton(buttonId);
    handleChangeLanguage(language);
  };

  return (
    <ToggleButtonGroup
      type="radio"
      name="options"
      defaultValue={1}
      style={{ marginBottom: "5px" }}
    >
      <ToggleButton
        key={1}
        id="radio-1"
        className={activeButton !== 1 ? "unchecked" : ""}
        style={{
          backgroundColor: activeButton === 1 ? "#FFFFFF" : "transparent",
          borderColor: "#FFFFFF",
          color: activeButton === 1 ? "#000000" : "#FFFFFF",
        }}
        value={1}
        onClick={() => handleButtonClick(1, "en")}
      >
        EN
      </ToggleButton>
      <ToggleButton
        key={2}
        id="radio-2"
        className={activeButton !== 2 ? "unchecked" : ""}
        style={{
          backgroundColor: activeButton === 2 ? "#00ffff" : "transparent",
          borderColor: "#00ffff",
          color: activeButton === 2 ? "#000000" : "#00ffff",
        }}
        value={2}
        onClick={() => handleButtonClick(2, "de")}
      >
        DE
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default LanguageSwitcher;
